import { BiCheck } from 'react-icons/bi';
import styled from 'styled-components';
import { deviceMax } from '../../../styles/MediaQuery';
import { black, blue, containerWidth, white } from '../../../styles/Variables';

export const Wrapper = styled.main`
    max-width: ${containerWidth};
    margin: auto;
    padding: 1em;
    font-size: 0.875em;
`;

export const SectionTitle = styled.h2`
    color: ${blue};
    font-size: 1.5rem;
    letter-spacing: 0.06rem;
`;

export const Row = styled.div`
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr;

    &:nth-child(even) {
        margin: 0 0 2rem 0;
    }

    * {
        font-family: Arial, Helvetica, sans-serif;
    }

    @media ${deviceMax.tablet} {
        grid-template-columns: 1fr;
    }
`;

export const Card = styled.div`
    background-color: ${white};
    flex: 1;
`;

export const Img = styled.img`
    width: 100%;
    height: 188px;

    @media ${deviceMax.tablet} {
        object-fit: scale-down;
    }
`;

export const Content = styled.div`
    padding: 1em 0.8em;
`;

export const Title = styled.h3`
    font-weight: 700;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
    margin: 0;
    font-size: 0.95rem;
    letter-spacing: 0.025rem;
`;

export const TitleSection = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3em;
    margin: 0 0 0.8em 0;
`;

export const Text = styled.p`
    color: ${black};
    font-weight: 300;
    margin: 0;

    * {
        font-size: 0.875em;
        line-height: 1.25rem;
    }
`;

export const TextDivider = styled.span`
    strong {
        font-weight: 700;
        font-size: 0.875rem;
    }
`;

export const CheckIcon = styled(BiCheck)`
    font-size: 1.9rem;
    margin-top: -0.18em;
    fill: ${blue};
`;
